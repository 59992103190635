(function () {
    var i;
    function cellWidth(element, cells) {
        var styles = window.getComputedStyle(element),
            padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight),
            actualWidth = element.clientWidth - padding;
        return actualWidth / cells;
    }
    function carouselMove(direction, slidesToMove, container, cellsToShow, transSpeed, slidesPerClick) {
        var scrollAmount = 0,
            containWidth = cellWidth(document.querySelector(container), cellsToShow),
            totalSlide = slidesPerClick * containWidth,
            slideSpeed = transSpeed,
            slideTimer = setInterval(function () {
                if (direction === "left") {
                    document.querySelector(container).scrollLeft -= slideSpeed / 10;
                } else if (direction === "right") {
                    document.querySelector(container).scrollLeft += slideSpeed / 10;
                }
                scrollAmount += slideSpeed / 10;
                if (scrollAmount >= totalSlide) {
                    window.clearInterval(slideTimer);
                }
            }, 10);
    }
    function buildCarousel() {
        var element = document.querySelector(this.options.appendTo),
            options = this.options,
            carContainer,
            cells = element.querySelectorAll(options.cellClass),
            numOfDots = Math.ceil(cells.length / options.cellsToShow),
            td,
            t,
            d,
            cellWidth = (100 / options.cellsToShow) + "%",
            leftArrow = document.createElement('div'),
            rightArrow = document.createElement('div');
        this.carouselOuter = document.createElement('div');
        this.carouselOuter.classList.add('js-carousel__outer');
        this.carouselOuter.style.maxWidth = options.maxWidth;
        element.classList.add('js-carousel__inner');
        element.classList.add('js-carousel__fade');
        element.parentNode.appendChild(this.carouselOuter);
        carContainer = element.parentNode.querySelector('.js-carousel__outer');
        leftArrow.classList.add('js-carousel__arrow');
        leftArrow.classList.add('icon-left-open');
        rightArrow.classList.add('js-carousel__arrow');
        rightArrow.classList.add('icon-right-open');
        if (options.arrows === false) {
            leftArrow.style.display = "none";
            rightArrow.style.display = "none";
        }
        this.carouselOuter.appendChild(leftArrow);
        carContainer.appendChild(element);
        this.carouselOuter.appendChild(rightArrow);
        for (i = 0; i < cells.length; i += 1) {
            cells[i].style.width = cellWidth;
            cells[i].style.minWidth = cellWidth;
            cells[i].classList.add('js-carousel__inner-cell');
            
        }
        setTimeout(function () {
            element.classList.remove('js-carousel__fade');
        }, 350);
    }
    function initializeType() {
        var options = this.options,
            appendHere = document.querySelector(options.appendTo),
            leftArrow = document.querySelector(options.appendTo).parentElement.querySelector('.icon-left-open'),
            rightArrow = document.querySelector(options.appendTo).parentElement.querySelector('.icon-right-open');
        leftArrow.addEventListener('click', function () {
            carouselMove("left", 1, options.appendTo, options.cellsToShow, options.transitionSpeed, options.slidesPerClick);
        });
        rightArrow.addEventListener('click', function () {
            carouselMove("right", 1, options.appendTo, options.cellsToShow, options.transitionSpeed, options.slidesPerClick);
        });
    }
    this.Carousel = function () {
        this.appendTo = null;
        this.arrows = null;
        this.cellClass = null;
        this.cellsToShow = null;
        this.fadeIn = null;
        this.images = null;
        this.maxWidth = null;
        this.slidesPerClick = null;
        this.transitionSpeed = null;
        var defaults = {
            appendTo: ".js-carousel",
            arrows: true,
            cellsToShow: 5,
            fadeIn: true,
            transitionSpeed: 300
        };
        function extendDefaults(source, properties) {
            var property;
            for (property in properties) {
                if (properties.hasOwnProperty(property)) {
                    source[property] = properties[property];
                }
            }
            return source;
        }
        if (arguments[0]) {
            if (typeof arguments[0] === "object") {
                this.options = extendDefaults(defaults, arguments[0]);
            }
        }
        buildCarousel.call(this);
        initializeType.call(this);
    };
    Carousel.prototype.destroy = function () {
        document.querySelector(this.options.appendTo).parentElement.outerHTML = "";
    };
}());